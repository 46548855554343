var stickyHeader = true;
// var transformHomepageDonationAmountImages = false;
// var flyoutNav = false;
// var fullscreenNav = false;
// var homeQuickGivingPreFooter = true;
// var countUpStats = false;
// var countUpStatsDuration = 3000;
// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
var subsiteTitleNotClickable = true;
// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video
// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;


// Add print button to .shareList
$('.shareList').append('<li><a class="printButton" href="javascript:window.print()">Print this page</a></li>');

// FooterLogos insert after page footer
$('.footerBox.FooterLogosAndLegal').insertAfter(".pageFooter");

// Add scrollbar to submenus https://raisingit.atlassian.net/browse/DES-5616
function calcSubmenuHeight() {;
  let headerHeight = $('#pageHeader').height();
  $('ul.level2').css('max-height', `calc(100vh - ${headerHeight}px)`);
}

$(window).on("load resize scroll", function(e){
  setTimeout(() => {
    calcSubmenuHeight();
  }, 100);
})